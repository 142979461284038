import React, { useState } from 'react';
import { cannonStyled, NSH3, TWH1, useCannonStyletron } from '../../../theme';
import PaypalSuccess from '../../../assets/payment-completed-card.svg';
import PayPalIcon from '../../../assets/paypal-icon.svg';
import { useOrientation, useTableOrMobile } from '../../../hooks';
import PayPal from '../../../atoms/PayPal';
import { Button, CustomDropdown } from '../../../atoms';

const ButtonContainer = cannonStyled('div', {
  paddingTop: '30px',
  width: '100%',
});

const MainContainer = cannonStyled(
  'div',
  ({ isRotated }: { isRotated: boolean }) => ({
    padding: isRotated ? '50px 0px 50px 0px' : '',
    width: '80%',
  })
);

interface ICombinationPaypal {
  title: string;
  subTitle: string;
}

const CombinationPaypal = (props: ICombinationPaypal) => {
  // HOOKS
  const [css, theme] = useCannonStyletron();
  const [isMobile, isTablet] = useTableOrMobile();

  //STATE
  const [amount, setAmount] = useState([
    { label: 'Please Choose an Option', id: '0' },
  ]);
  const [showTitle, setShowTitle] = useState(true);
  const currency = 'USD';

  // RENDER
  const { isRotated } = useOrientation();

  return (
    <MainContainer isRotated={isRotated}>
      <ButtonContainer>
        {showTitle &&
          <>
            <PayPalIcon />
            <NSH3
              color={theme.cannonColors?.primaryBlue}
              weight={900}
              align={isMobile ? 'left' : 'center'}
              margin={20}
            >
              {props.title}
            </NSH3>
            <TWH1
              color={theme.cannonColors?.primaryGray}
              weight={400}
              align={isMobile ? 'left' : 'center'}
              style={{ fontSize: `min(16px, 18px)` }}
              margin={5}
            >
              {props.subTitle}
            </TWH1>
          </>
        }
        <PayPal
          amount={30}
          currency={'USD'}
          items={[]}
          successfulImage={PaypalSuccess}
          description={amount[0].label}
          successfulText={
            'We have sent your key(s) replacement confirmation to your mail. Be sure emails from cannonsecurityproducts.com and cannonsafe.com are on your safe sender list.'
          }
          successfulTitle={'Your payment was completed successfully'}
          onPaymentApproved={(value) => {
            setShowTitle(false)
            // handleSendData(value);
            // setPaymentDone(true);
          }}
        />
      </ButtonContainer>
    </MainContainer>
  );
};

export default CombinationPaypal;
